import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import 'github-markdown-css'; // Optional, for styling the Markdown content

 // Path to your markdown files
import markdownContent1 from '../MarkdownFiles/Divorce.md';
import markdownContent2 from "../MarkdownFiles/Mutaldivorce.md";
import markdownContent3 from "../MarkdownFiles/Crimecyber.md";
import markdownContent4 from "../MarkdownFiles/Accountfreezing.md";
import markdownContent5 from "../MarkdownFiles/Fir.md";
import markdownContent6 from "../MarkdownFiles/Judicial.md";
import markdownContent7 from "../MarkdownFiles/Conjugal.md";
import markdownContent8 from "../MarkdownFiles/SafeguardingVictims.md";
import markdownContent9 from "../MarkdownFiles/ForeignDivorceValidity.md";
import markdownContent10 from "../MarkdownFiles/IdTheftCyberSpace.md";
import markdownContent11 from "../MarkdownFiles/WifeProfitHusbandBusiness.md"
import markdownContent12 from "../MarkdownFiles/FTCvRegularCourts.md"

const markdownContents = {
  '1': markdownContent1,
  '2':markdownContent2,
  '3': markdownContent3,
  '4':markdownContent4,
  '5':markdownContent5,
  '6': markdownContent6,
  '7':markdownContent7,
  '8':markdownContent8,
  '9':markdownContent9,
  '10':markdownContent10,
  '11':markdownContent11,
  '12':markdownContent12
  // Add more entries here if you have more markdown files
};
function App() {
  const [content, setContent] = React.useState('');
  const { id } = useParams(); // Extract the blog ID from the URL

  React.useEffect(() => {
    // Construct the file path based on the blogId
    console.log(id);
    console.log(markdownContents[id]);
    const markdownToLoad = markdownContents[id  ] || markdownContents['2'];

    fetch(markdownToLoad)
      .then((response) => response.text())
      .then((text) => setContent(text))
      .catch((error) => console.error('Error fetching Markdown content:', error));
  }, [id]); // Rerun effect when blogId changes

  return (
    <div className="container mt-5">
      <ReactMarkdown children={content} />
    </div>
  );
}

export default App;

