import divorceImg from "../img/divorce1.jpg";
import divorceImg2 from "../img/mutaldivorce.png";
import divorceImg3 from "../img/cybercrimelaw.jpg";
import divorceImg4 from "../img/accountfreezing.jpg";
import divorceImg5 from "../img/fir.jpg";
import divorceImg7 from "../img/jujgual.jpg";
import divorceImg6 from "../img/seperation.jpg";
import divorceImg8 from "../img/dom_violence.jpg";
import divorceImg9 from "../img/divorce-decree.jpg";
import divorceImg10 from "../img/id_theft.jpg";
import divorceImg11 from "../img/family_business.jpg";
import divorceImg12 from "../img/ftc_regular_courts.jpg";

const blogPosts = [
    {
      id: 1,
      image: divorceImg,
      title1: 'Divorce Mediation',
 
    },
    {
      id: 2,
      image: divorceImg2,
      title1: 'Divorce by Mutual Consent in India',

    },
    {
      id: 3,
      title1: 'Cyber Crime Law in India',
      image: divorceImg3,
    },
  {
    id: 4,
    image: divorceImg4,
    title1: 'Account Freezing',

  },
  {
    id: 5,
    image: divorceImg5,
    title1: 'FIR',

  },
  {
    id: 6,
    image: divorceImg6,
    title1: 'Judicial Separation',

  },
  {
    id: 7,
    image: divorceImg7,
    title1: 'Restitution Conjugal Rigths ',
  },
  {
    id: 8,
    image: divorceImg8,
    title1: 'Safeguarding Victims Laws Protecting Domestic Abuse Victims in India',
  },
  {
    id: 9,
    image: divorceImg9,
    title1: 'Validity of Divorce decree passed by a foreign court in India',
  },
  {
    id: 10,
    image: divorceImg10,
    title1: 'Identity Theft in Cyber Space',
  },
  {
    id: 11,
    image: divorceImg11,
    title1: `Can Wife seek share in profits from Husband's Family run business?`,
  },
  {
    id: 12,
    image: divorceImg12,
    title1: `Distinction Between Fast Track Courts (FTC) and Regular Courts`,
  },
];
  
  export default blogPosts;
  