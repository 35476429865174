import React from "react";
import Img from "../img/Property.jpg";

const Economic_Offens = () => {
  return (
    <div>
      <div className="banner-image">
        <img src={Img} alt="" />
      </div>
      <div className="contanior"> Coming Soon</div>
    </div>
  );
};

export default Economic_Offens;
