import React from "react";
import Img from "../img/criminal_law.jpg";

const Criminal_Cases = () => {
  return (
    <div>
      <div className='banner-image'>
        <img src={Img} alt="" />
        Coming soon
      </div>
    </div>
  );
};

export default Criminal_Cases;
