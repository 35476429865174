import m1 from "../img/team/2.jpg";
import m2 from "../img/team/3.jpg";
import m3 from "../img/team/1.jpg";
import m4 from "../img/team/5.jpg";
import m5 from "../img/team/4.jpg";


export const OurTeams =[
    {
        id:1,
        image:m1,
        title:'Akash Shetty',
        Position:'Managing Director',
        twitter:'',
        facebook:'',
        instagram:'', 
    },
    {
        id:2,
        image:m2,
        title:'B M Gaonkar',
        Position:'Managing Director',
        twitter:'',
        facebook:'',
        instagram:'', 
    },
    {
        id:3,
        image:m3,
        title:'Surya',
        Position:'',
        twitter:'',
        facebook:'',
        instagram:'', 
    },
    {
        id:4,
        image:m4,
        title:'Sridevi',
        Position:'',
        twitter:'',
        facebook:'',
        instagram:'', 
    },
    {
        id:5,
        image:m5,
        title:'Pavan',
        Position:'',
        twitter:'',
        facebook:'',
        instagram:'', 
    },
   
]